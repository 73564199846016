<template>
  <div class="auth-wrapper auth-v2" style="height:100vh">
    <!-- <b-overlay
      style="height:100vh"
      :show="loading"
      spinner-variant="primary"
      spinner-large
      rounded="lg"
    >
    </b-overlay> -->

    <div id="loading-bg">
      <div class="loading-logo">
          <img class="loading-logo-image" src="logo.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { loggedOnce } from "@/vue-apollo";
import { BOverlay } from "bootstrap-vue";
import { mapActions } from "vuex";
//Graph QL
import GET_USER_INFO from "@/graphql/login/query/GetUserData.gql";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  getHomeRouteForLoggedInUser,
  getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserProfileImage,
  getUserEmail
} from "@/auth/utils";

export default {
  components: {
    BOverlay
  },
  created() {
    // local storage to check if it's google or microsoft
    if (localStorage.getItem("amplify-redirected-from-hosted-ui")) {
      this.authUserGenerator();
    } else {
      this.$router.push({ name: "auth-login" });
    }
  },
  data() {
    return {
      loading: true
    };
  },
  methods: {
    ...mapActions({
      fetchUserAndCompanyInfo: "userSettings/fetchUserAndCompanyInfo",
    }),
    intro(data) {
      this.$swal({
        title: `Welcome Back ${data}`,
        icon: "success",
        html: "Logged in successfully.",
        timer: 3000,
        confirmButtonColor: '#00A0F2',
        allowOutsideClick: true,
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      }).then(() => {
        // this is where we restart the socket connection
        //location.reload();
      });
    },
    async authUserGenerator() {
      console.log("IN AUTH USER GENERATOR");
      try {
        loggedOnce.state = true;
        this.$ability.update([
          {
            action: "manage",
            subject: "all"
          }
        ]);
        let data = await Auth.currentSession()
        console.log(data);
        localStorage.setItem("JoT", JSON.stringify(data.idToken.jwtToken));
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(data.refreshToken.token)
        );
        localStorage.setItem("Role", getUserRole());
        localStorage.setItem("SiteId", getUserSiteId());
        let userData = {
          fullName: getUserName(),
          username: getUserName(),
          avatar: getUserProfileImage(),
          email: getUserEmail(),
          role: getUserRole(),
          ability: [
            {
              action: "manage",
              subject: "all"
            }
          ],
          accessToken: data.accessToken.jwtToken,
          refreshToken: data.refreshToken.token
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("userId", getUserId());
        await this.fetchUserAndCompanyInfo()
        this.$router
          .replace(getHomeRouteForLoggedInUser("admin"))
          .then(() => {
            this.intro(userData.fullName.split(" ")[0]);
          });
        // let userInfo = JSON.parse(localStorage.getItem("userData"));
        this.$ability.update([
          {
            action: "manage",
            subject: "all"
          }
        ]);
      } catch (loginErr) {
        console.info(loginErr);
        this.$toast.warning({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Login Failed",
            text: "Authentication Failed"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(50% - 30px);
  top: 38%;
}

.loading-logo-image{
  width:60px;
  margin-left:3px;
}

.loading {
  position: absolute;
  left: calc(50% - 25px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #00A0F3;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #00A0F3;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
